
<div class="main-wrapper">
  <app-header></app-header>
  <div class="sidebar" id="sidebar">
      <div class="sidebar-inner slimscroll">
          <div class="sidebar-menu">
              <ul>
                  <li>
                      <a href="dashboard"><i class="fa fa-home back-icon"></i> <span>Back to Home</span></a>
                  </li>
                  <li class="menu-title">Settings</li>
                  <li class="active">
                      <a href="settings.html"><i class="fa fa-building"></i> <span>Company Settings</span></a>
                  </li>
                  <li>
                      <a href="emailSetting"><i class="fa fa-envelope-o"></i> <span>Email Settings</span></a>
                  </li>
                  <li>
                      <a href="notifications"><i class="fa fa-globe"></i> <span>Notifications</span></a>
                  </li>
                  <li>
                      <a href="changePassword"><i class="fa fa-lock"></i> <span>Change Password</span></a>
                  </li>

              </ul>
          </div>
      </div>
  </div>

  <div class="page-wrapper">
      <div class="content">
          <div class="row">
              <div class="col-lg-8 offset-lg-2">
                  <h4 class="page-title">Notifications Settings</h4>
                  <div>
                      <ul class="list-group notification-list">
                          <li class="list-group-item">
                              Employee
                              <div class="material-switch float-right">
                                  <input id="staff_module" type="checkbox" checked="checked">
                                  <label for="staff_module" class="badge-primary"></label>
                              </div>
                          </li>
                          <li class="list-group-item">
                              Holidays
                              <div class="material-switch float-right">
                                  <input id="holidays_module" type="checkbox">
                                  <label for="holidays_module" class="badge-primary"></label>
                              </div>
                          </li>
                          <li class="list-group-item">
                              Leave Request
                              <div class="material-switch float-right">
                                  <input id="leave_module" type="checkbox">
                                  <label for="leave_module" class="badge-primary"></label>
                              </div>
                          </li>
                          <li class="list-group-item">
                              Events
                              <div class="material-switch float-right">
                                  <input id="events_module" type="checkbox">
                                  <label for="events_module" class="badge-primary"></label>
                              </div>
                          </li>
                          <li class="list-group-item">
                              Chat
                              <div class="material-switch float-right">
                                  <input id="chat_module" type="checkbox">
                                  <label for="chat_module" class="badge-primary"></label>
                              </div>
                          </li>
                      </ul>
                  </div>
              </div>
          </div>
      </div>
      <div class="notification-box">
          <div class="msg-sidebar notifications msg-noti">
              <div class="topnav-dropdown-header">
                  <span>Messages</span>
              </div>
              <div class="drop-scroll msg-list-scroll" id="msg_list">
                  <ul class="list-box">
                      <li>
                          <a href="chat">
                              <div class="list-item">
                                  <div class="list-left">
                                      <span class="avatar">R</span>
                                  </div>
                                  <div class="list-body">
                                      <span class="message-author">Richard Miles </span>
                                      <span class="message-time">12:28 AM</span>
                                      <div class="clearfix"></div>
                                      <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                  </div>
                              </div>
                          </a>
                      </li>
                      <li>
                          <a href="chat">
                              <div class="list-item new-message">
                                  <div class="list-left">
                                      <span class="avatar">J</span>
                                  </div>
                                  <div class="list-body">
                                      <span class="message-author">John Doe</span>
                                      <span class="message-time">1 Aug</span>
                                      <div class="clearfix"></div>
                                      <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                  </div>
                              </div>
                          </a>
                      </li>
                      <li>
                          <a href="chat">
                              <div class="list-item">
                                  <div class="list-left">
                                      <span class="avatar">T</span>
                                  </div>
                                  <div class="list-body">
                                      <span class="message-author"> Tarah Shropshire </span>
                                      <span class="message-time">12:28 AM</span>
                                      <div class="clearfix"></div>
                                      <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                  </div>
                              </div>
                          </a>
                      </li>
                      <li>
                          <a href="chat">
                              <div class="list-item">
                                  <div class="list-left">
                                      <span class="avatar">M</span>
                                  </div>
                                  <div class="list-body">
                                      <span class="message-author">Mike Litorus</span>
                                      <span class="message-time">12:28 AM</span>
                                      <div class="clearfix"></div>
                                      <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                  </div>
                              </div>
                          </a>
                      </li>
                      <li>
                          <a href="chat">
                              <div class="list-item">
                                  <div class="list-left">
                                      <span class="avatar">C</span>
                                  </div>
                                  <div class="list-body">
                                      <span class="message-author"> Catherine Manseau </span>
                                      <span class="message-time">12:28 AM</span>
                                      <div class="clearfix"></div>
                                      <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                  </div>
                              </div>
                          </a>
                      </li>
                      <li>
                          <a href="chat">
                              <div class="list-item">
                                  <div class="list-left">
                                      <span class="avatar">D</span>
                                  </div>
                                  <div class="list-body">
                                      <span class="message-author"> Domenic Houston </span>
                                      <span class="message-time">12:28 AM</span>
                                      <div class="clearfix"></div>
                                      <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                  </div>
                              </div>
                          </a>
                      </li>
                      <li>
                          <a href="chat">
                              <div class="list-item">
                                  <div class="list-left">
                                      <span class="avatar">B</span>
                                  </div>
                                  <div class="list-body">
                                      <span class="message-author"> Buster Wigton </span>
                                      <span class="message-time">12:28 AM</span>
                                      <div class="clearfix"></div>
                                      <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                  </div>
                              </div>
                          </a>
                      </li>
                      <li>
                          <a href="chat">
                              <div class="list-item">
                                  <div class="list-left">
                                      <span class="avatar">R</span>
                                  </div>
                                  <div class="list-body">
                                      <span class="message-author"> Rolland Webber </span>
                                      <span class="message-time">12:28 AM</span>
                                      <div class="clearfix"></div>
                                      <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                  </div>
                              </div>
                          </a>
                      </li>
                      <li>
                          <a href="chat">
                              <div class="list-item">
                                  <div class="list-left">
                                      <span class="avatar">C</span>
                                  </div>
                                  <div class="list-body">
                                      <span class="message-author"> Claire Mapes </span>
                                      <span class="message-time">12:28 AM</span>
                                      <div class="clearfix"></div>
                                      <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                  </div>
                              </div>
                          </a>
                      </li>
                      <li>
                          <a href="chat">
                              <div class="list-item">
                                  <div class="list-left">
                                      <span class="avatar">M</span>
                                  </div>
                                  <div class="list-body">
                                      <span class="message-author">Melita Faucher</span>
                                      <span class="message-time">12:28 AM</span>
                                      <div class="clearfix"></div>
                                      <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                  </div>
                              </div>
                          </a>
                      </li>
                      <li>
                          <a href="chat">
                              <div class="list-item">
                                  <div class="list-left">
                                      <span class="avatar">J</span>
                                  </div>
                                  <div class="list-body">
                                      <span class="message-author">Jeffery Lalor</span>
                                      <span class="message-time">12:28 AM</span>
                                      <div class="clearfix"></div>
                                      <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                  </div>
                              </div>
                          </a>
                      </li>
                      <li>
                          <a href="chat">
                              <div class="list-item">
                                  <div class="list-left">
                                      <span class="avatar">L</span>
                                  </div>
                                  <div class="list-body">
                                      <span class="message-author">Loren Gatlin</span>
                                      <span class="message-time">12:28 AM</span>
                                      <div class="clearfix"></div>
                                      <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                  </div>
                              </div>
                          </a>
                      </li>
                      <li>
                          <a href="chat">
                              <div class="list-item">
                                  <div class="list-left">
                                      <span class="avatar">T</span>
                                  </div>
                                  <div class="list-body">
                                      <span class="message-author">Tarah Shropshire</span>
                                      <span class="message-time">12:28 AM</span>
                                      <div class="clearfix"></div>
                                      <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                  </div>
                              </div>
                          </a>
                      </li>
                  </ul>
              </div>
              <div class="topnav-dropdown-footer">
                  <a href="chat">See all messages</a>
              </div>
          </div>
      </div>
  </div>
</div>
