import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-incomming-call',
  templateUrl: './incomming-call.component.html',
  styleUrls: ['./incomming-call.component.css']
})
export class IncommingCallComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
