import { Injectable } from '@angular/core';
//This is where you make API calls. There will be one repository for one functional module

@Injectable({
  providedIn: 'root'
})
export class AppointmentRepositoryService {

   // inject HttpClient in the constructor
   constructor() { }

   // Get the URL from environment file

   // http Call to get , post, put
}
