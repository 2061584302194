import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-clinic-information',
  templateUrl: './clinic-information.component.html',
  styleUrls: ['./clinic-information.component.css']
})
export class ClinicInformationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
