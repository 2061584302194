import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mailview',
  templateUrl: './mailview.component.html',
  styleUrls: ['./mailview.component.css']
})
export class MailviewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
