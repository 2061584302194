<div class="main-wrapper">
  <app-menu></app-menu>

  <div class="page-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-sm-4 col-3">
          <h4 class="page-title">Appointments</h4>
        </div>
        <div class="col-sm-8 col-9 text-right m-b-20">
          <a href="addPets" class="btn btn-primary btn-rounded float-right text-light"
            ><i class="fa fa-plus"></i> Add Pets</a
          >
        </div>
        <div class="col-sm-12 text-right m-b-20">
          <a
            class="btn btn-secondary btn-rounded float-right text-light"
            (click)="lists()"
            >List View
          </a>
          <a
            class="btn btn-secondary btn-rounded float-right text-light"
            (click)="cards()"
            >Card View</a
          >
        </div>
      </div>
      <div class="row" *ngIf="list">
        <div class="col-md-12">
          <div class="table-responsive">
            <table class="table table-striped custom-table">
              <thead>
                  <tr>
                      <th>Name</th>
                      <th>Age</th>
                      <th>Address</th>
                      <th>Phone</th>
                      <th>Email</th>
                      <th class="text-right">Action</th>
                  </tr>
              </thead>
              <tbody>
                  <tr>
                      <td><img width="28" height="28" src="assets/img/user.jpg" class="rounded-circle m-r-5" alt=""> Jennifer Robinson</td>
                      <td>35</td>
                      <td>1545 Dorsey Ln NE, Leland, NC, 28451</td>
                      <td>(207) 808 8863</td>
                      <td>jenniferrobinson@example.com</td>
                      <td class="text-right">
                          <div class="dropdown dropdown-action">
                              <a href="#" class="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
                              <div class="dropdown-menu dropdown-menu-right">
                                  <a class="dropdown-item" href="edit-patient.html"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                                  <a class="dropdown-item" href="#" data-toggle="modal" data-target="#delete_patient"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                              </div>
                          </div>
                      </td>
                  </tr>
                  <tr>
                      <td><img width="28" height="28" src="assets/img/user.jpg" class="rounded-circle m-r-5" alt=""> Terry Baker</td>
                      <td>63</td>
                      <td>555 Front St #APT 2H, Hempstead, NY, 11550</td>
                      <td>(376) 150 6975</td>
                      <td>terrybaker@example.com</td>
                      <td class="text-right">
                          <div class="dropdown dropdown-action">
                              <a href="#" class="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
                              <div class="dropdown-menu dropdown-menu-right">
                                  <a class="dropdown-item" href="edit-patient.html"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                                  <a class="dropdown-item" href="#" data-toggle="modal" data-target="#delete_patient"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                              </div>
                          </div>
                      </td>
                  </tr>
                  <tr>
                      <td><img width="28" height="28" src="assets/img/user.jpg" class="rounded-circle m-r-5" alt=""> Kyle Bowman</td>
                      <td>7</td>
                      <td>5060 Fairways Cir #APT 207, Vero Beach, FL, 32967</td>
                      <td>(981) 756 6128</td>
                      <td>kylebowman@example.com</td>
                      <td class="text-right">
                          <div class="dropdown dropdown-action">
                              <a href="#" class="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
                              <div class="dropdown-menu dropdown-menu-right">
                                  <a class="dropdown-item" href="edit-patient.html"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                                  <a class="dropdown-item" href="#" data-toggle="modal" data-target="#delete_patient"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                              </div>
                          </div>
                      </td>
                  </tr>
                  <tr>
                      <td><img width="28" height="28" src="assets/img/user.jpg" class="rounded-circle m-r-5" alt=""> Marie Howard</td>
                      <td>22</td>
                      <td>3501 New Haven Ave #152, Columbia, MO, 65201</td>
                      <td>(634) 09 3833</td>
                      <td>mariehoward@example.com</td>
                      <td class="text-right">
                          <div class="dropdown dropdown-action">
                              <a href="#" class="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
                              <div class="dropdown-menu dropdown-menu-right">
                                  <a class="dropdown-item" href="edit-patient.html"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                                  <a class="dropdown-item" href="#" data-toggle="modal" data-target="#delete_patient"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                              </div>
                          </div>
                      </td>
                  </tr>
                  <tr>
                      <td><img width="28" height="28" src="assets/img/user.jpg" class="rounded-circle m-r-5" alt=""> Joshua Guzman</td>
                      <td>34</td>
                      <td>4712 Spring Creek Dr, Bonita Springs, FL, 34134</td>
                      <td>(407) 554 4146</td>
                      <td>joshuaguzman@example.com</td>
                      <td class="text-right">
                          <div class="dropdown dropdown-action">
                              <a href="#" class="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
                              <div class="dropdown-menu dropdown-menu-right">
                                  <a class="dropdown-item" href="edit-patient.html"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                                  <a class="dropdown-item" href="#" data-toggle="modal" data-target="#delete_patient"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                              </div>
                          </div>
                      </td>
                  </tr>
                  <tr>
                      <td><img width="28" height="28" src="assets/img/user.jpg" class="rounded-circle m-r-5" alt=""> Julia Sims</td>
                      <td>27</td>
                      <td>517 Walker Dr, Houma, LA, 70364, United States</td>
                      <td>(680) 432 2662</td>
                      <td>juliasims@example.com</td>
                      <td class="text-right">
                          <div class="dropdown dropdown-action">
                              <a href="#" class="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
                              <div class="dropdown-menu dropdown-menu-right">
                                  <a class="dropdown-item" href="edit-patient.html"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                                  <a class="dropdown-item" href="#" data-toggle="modal" data-target="#delete_patient"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                              </div>
                          </div>
                      </td>
                  </tr>
                  <tr>
                      <td><img width="28" height="28" src="assets/img/user.jpg" class="rounded-circle m-r-5" alt=""> Linda Carpenter</td>
                      <td>24</td>
                      <td>2226 Victory Garden Ln, Tallahassee, FL, 32301</td>
                      <td>(218) 661 8316</td>
                      <td>lindacarpenter@example.com</td>
                      <td class="text-right">
                          <div class="dropdown dropdown-action">
                              <a href="#" class="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
                              <div class="dropdown-menu dropdown-menu-right">
                                  <a class="dropdown-item" href="edit-patient.html"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                                  <a class="dropdown-item" href="#" data-toggle="modal" data-target="#delete_patient"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                              </div>
                          </div>
                      </td>
                  </tr>
                  <tr>
                      <td><img width="28" height="28" src="assets/img/user.jpg" class="rounded-circle m-r-5" alt=""> Melissa Burton</td>
                      <td>35</td>
                      <td>3321 N 26th St, Milwaukee, WI, 53206</td>
                      <td>(192) 494 8073</td>
                      <td>melissaburton@example.com</td>
                      <td class="text-right">
                          <div class="dropdown dropdown-action">
                              <a href="#" class="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
                              <div class="dropdown-menu dropdown-menu-right">
                                  <a class="dropdown-item" href="edit-patient.html"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                                  <a class="dropdown-item" href="#" data-toggle="modal" data-target="#delete_patient"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                              </div>
                          </div>
                      </td>
                  </tr>
                  <tr>
                      <td><img width="28" height="28" src="assets/img/user.jpg" class="rounded-circle m-r-5" alt=""> Patrick Knight</td>
                      <td>21</td>
                      <td>Po Box 3336, Commerce, TX, 75429</td>
                      <td>(785) 580 4514</td>
                      <td>patrickknight@example.com</td>
                      <td class="text-right">
                          <div class="dropdown dropdown-action">
                              <a href="#" class="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
                              <div class="dropdown-menu dropdown-menu-right">
                                  <a class="dropdown-item" href="edit-patient.html"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                                  <a class="dropdown-item" href="#" data-toggle="modal" data-target="#delete_patient"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                              </div>
                          </div>
                      </td>
                  </tr>
                  <tr>
                      <td><img width="28" height="28" src="assets/img/user.jpg" class="rounded-circle m-r-5" alt=""> Denise Stevens</td>
                      <td>7</td>
                      <td>1603 Old York Rd, Abington, PA, 19001</td>
                      <td>(836) 257 1379</td>
                      <td>denisestevens@example.com</td>
                      <td class="text-right">
                          <div class="dropdown dropdown-action">
                              <a href="#" class="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
                              <div class="dropdown-menu dropdown-menu-right">
                                  <a class="dropdown-item" href="edit-patient.html"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                                  <a class="dropdown-item" href="#" data-toggle="modal" data-target="#delete_patient"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                              </div>
                          </div>
                      </td>
                  </tr>
                  <tr>
                      <td><img width="28" height="28" src="assets/img/user.jpg" class="rounded-circle m-r-5" alt=""> Judy Clark</td>
                      <td>22</td>
                      <td>4093 Woodside Circle, Pensacola, FL, 32514</td>
                      <td>(359) 969 3594</td>
                      <td>judy.clark@example.com</td>
                      <td class="text-right">
                          <div class="dropdown dropdown-action">
                              <a href="#" class="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
                              <div class="dropdown-menu dropdown-menu-right">
                                  <a class="dropdown-item" href="edit-patient.html"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                                  <a class="dropdown-item" href="#" data-toggle="modal" data-target="#delete_patient"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                              </div>
                          </div>
                      </td>
                  </tr>
                  <tr>
                      <td><img width="28" height="28" src="assets/img/user.jpg" class="rounded-circle m-r-5" alt=""> Dennis Salazar</td>
                      <td>34</td>
                      <td>891 Juniper Drive, Saginaw, MI, 48603</td>
                      <td>(933) 137 6201</td>
                      <td>dennissalazar@example.com</td>
                      <td class="text-right">
                          <div class="dropdown dropdown-action">
                              <a href="#" class="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
                              <div class="dropdown-menu dropdown-menu-right">
                                  <a class="dropdown-item" href="edit-patient.html"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                                  <a class="dropdown-item" href="#" data-toggle="modal" data-target="#delete_patient"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                              </div>
                          </div>
                      </td>
                  </tr>
                  <tr>
                      <td><img width="28" height="28" src="assets/img/user.jpg" class="rounded-circle m-r-5" alt=""> Charles Ortega</td>
                      <td>32</td>
                      <td>3169 Birch Street, El Paso, TX, 79915</td>
                      <td>(380) 141 1885</td>
                      <td>charlesortega@example.com</td>
                      <td class="text-right">
                          <div class="dropdown dropdown-action">
                              <a href="#" class="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
                              <div class="dropdown-menu dropdown-menu-right">
                                  <a class="dropdown-item" href="edit-patient.html"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                                  <a class="dropdown-item" href="#" data-toggle="modal" data-target="#delete_patient"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                              </div>
                          </div>
                      </td>
                  </tr>
                  <tr>
                      <td><img width="28" height="28" src="assets/img/user.jpg" class="rounded-circle m-r-5" alt=""> Sandra Mendez</td>
                      <td>24</td>
                      <td>2535 Linden Avenue, Orlando, FL, 32789</td>
                      <td>(797) 506 1265</td>
                      <td>sandramendez@example.com</td>
                      <td class="text-right">
                          <div class="dropdown dropdown-action">
                              <a href="#" class="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
                              <div class="dropdown-menu dropdown-menu-right">
                                  <a class="dropdown-item" href="edit-patient.html"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                                  <a class="dropdown-item" href="#" data-toggle="modal" data-target="#delete_patient"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                              </div>
                          </div>
                      </td>
                  </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="row doctor-grid" *ngIf="card">
        <div class="col-md-4 col-sm-4 col-lg-3">
          <div class="profile-widget">
            <div class="doctor-img">
              <a class="avatar" href="#"
                ><img alt="" src="assets/img/patient7.jpg"
              /></a>
            </div>
            <div class="dropdown profile-action">
              <a
                href="#"
                class="action-icon dropdown-toggle"
                data-toggle="dropdown"
                aria-expanded="false"
                ><i class="fa fa-ellipsis-v"></i
              ></a>
              <div class="dropdown-menu dropdown-menu-right">
                <a class="dropdown-item" href="edit-doctor.html"
                  ><i class="fa fa-pencil m-r-5"></i> Edit</a
                >
                <a
                  class="dropdown-item"
                  href="#"
                  data-toggle="modal"
                  data-target="#delete_doctor"
                  ><i class="fa fa-trash-o m-r-5"></i> Delete</a
                >
              </div>
            </div>
            <h4 class="doctor-name text-ellipsis">
              <a href="profile.html">Michael Sullivan</a>
            </h4>

            <div class="user-country">
              <i class="fa fa-map-marker"></i> United States, San Francisco
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-4 col-lg-3">
          <div class="profile-widget">
            <div class="doctor-img">
              <a class="avatar" href="profile.html"
                ><img alt="" src="assets/img/patient8.jpg"
              /></a>
            </div>
            <div class="dropdown profile-action">
              <a
                href="#"
                class="action-icon dropdown-toggle"
                data-toggle="dropdown"
                aria-expanded="false"
                ><i class="fa fa-ellipsis-v"></i
              ></a>
              <div class="dropdown-menu dropdown-menu-right">
                <a class="dropdown-item" href="edit-doctor.html"
                  ><i class="fa fa-pencil m-r-5"></i> Edit</a
                >
                <a
                  class="dropdown-item"
                  href="#"
                  data-toggle="modal"
                  data-target="#delete_doctor"
                  ><i class="fa fa-trash-o m-r-5"></i> Delete</a
                >
              </div>
            </div>
            <h4 class="doctor-name text-ellipsis">
              <a href="profile.html">Linda Barrett</a>
            </h4>

            <div class="user-country">
              <i class="fa fa-map-marker"></i> United States, San Francisco
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-4 col-lg-3">
          <div class="profile-widget">
            <div class="doctor-img">
              <a class="avatar" href="profile.html"
                ><img alt="" src="assets/img/patient9.jpg"
              /></a>
            </div>
            <div class="dropdown profile-action">
              <a
                href="#"
                class="action-icon dropdown-toggle"
                data-toggle="dropdown"
                aria-expanded="false"
                ><i class="fa fa-ellipsis-v"></i
              ></a>
              <div class="dropdown-menu dropdown-menu-right">
                <a class="dropdown-item" href="edit-doctor.html"
                  ><i class="fa fa-pencil m-r-5"></i> Edit</a
                >
                <a
                  class="dropdown-item"
                  href="#"
                  data-toggle="modal"
                  data-target="#delete_doctor"
                  ><i class="fa fa-trash-o m-r-5"></i> Delete</a
                >
              </div>
            </div>
            <h4 class="doctor-name text-ellipsis">
              <a href="profile.html">Ronald Jacobs</a>
            </h4>

            <div class="user-country">
              <i class="fa fa-map-marker"></i> United States, San Francisco
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-4 col-lg-3">
          <div class="profile-widget">
            <div class="doctor-img">
              <a class="avatar" href="profile.html"
                ><img alt="" src="assets/img/patient10.jpg"
              /></a>
            </div>
            <div class="dropdown profile-action">
              <a
                href="#"
                class="action-icon dropdown-toggle"
                data-toggle="dropdown"
                aria-expanded="false"
                ><i class="fa fa-ellipsis-v"></i
              ></a>
              <div class="dropdown-menu dropdown-menu-right">
                <a class="dropdown-item" href="edit-doctor.html"
                  ><i class="fa fa-pencil m-r-5"></i> Edit</a
                >
                <a
                  class="dropdown-item"
                  href="#"
                  data-toggle="modal"
                  data-target="#delete_doctor"
                  ><i class="fa fa-trash-o m-r-5"></i> Delete</a
                >
              </div>
            </div>
            <h4 class="doctor-name text-ellipsis">
              <a href="profile.html">Albert Sandoval</a>
            </h4>

            <div class="user-country">
              <i class="fa fa-map-marker"></i> United States, San Francisco
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-4 col-lg-3">
          <div class="profile-widget">
            <div class="doctor-img">
              <a class="avatar" href="profile.html"
                ><img alt="" src="assets/img/patient11.jpg"
              /></a>
            </div>
            <div class="dropdown profile-action">
              <a
                href="#"
                class="action-icon dropdown-toggle"
                data-toggle="dropdown"
                aria-expanded="false"
                ><i class="fa fa-ellipsis-v"></i
              ></a>
              <div class="dropdown-menu dropdown-menu-right">
                <a class="dropdown-item" href="edit-doctor.html"
                  ><i class="fa fa-pencil m-r-5"></i> Edit</a
                >
                <a
                  class="dropdown-item"
                  href="#"
                  data-toggle="modal"
                  data-target="#delete_doctor"
                  ><i class="fa fa-trash-o m-r-5"></i> Delete</a
                >
              </div>
            </div>
            <h4 class="doctor-name text-ellipsis">
              <a href="profile.html">Diana Bailey</a>
            </h4>

            <div class="user-country">
              <i class="fa fa-map-marker"></i> United States, San Francisco
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-4 col-lg-3">
          <div class="profile-widget">
            <div class="doctor-img">
              <a class="avatar" href="profile.html"
                ><img alt="" src="assets/img/patient12.jpg"
              /></a>
            </div>
            <div class="dropdown profile-action">
              <a
                href="#"
                class="action-icon dropdown-toggle"
                data-toggle="dropdown"
                aria-expanded="false"
                ><i class="fa fa-ellipsis-v"></i
              ></a>
              <div class="dropdown-menu dropdown-menu-right">
                <a class="dropdown-item" href="edit-doctor.html"
                  ><i class="fa fa-pencil m-r-5"></i> Edit</a
                >
                <a
                  class="dropdown-item"
                  href="#"
                  data-toggle="modal"
                  data-target="#delete_doctor"
                  ><i class="fa fa-trash-o m-r-5"></i> Delete</a
                >
              </div>
            </div>
            <h4 class="doctor-name text-ellipsis">
              <a href="profile.html">Shirley Willis</a>
            </h4>

            <div class="user-country">
              <i class="fa fa-map-marker"></i> United States, San Francisco
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-4 col-lg-3">
          <div class="profile-widget">
            <div class="doctor-img">
              <a class="avatar" href="profile.html"
                ><img alt="" src="assets/img/patient-thumb-02.jpg"
              /></a>
            </div>
            <div class="dropdown profile-action">
              <a
                href="#"
                class="action-icon dropdown-toggle"
                data-toggle="dropdown"
                aria-expanded="false"
                ><i class="fa fa-ellipsis-v"></i
              ></a>
              <div class="dropdown-menu dropdown-menu-right">
                <a class="dropdown-item" href="edit-doctor.html"
                  ><i class="fa fa-pencil m-r-5"></i> Edit</a
                >
                <a
                  class="dropdown-item"
                  href="#"
                  data-toggle="modal"
                  data-target="#delete_doctor"
                  ><i class="fa fa-trash-o m-r-5"></i> Delete</a
                >
              </div>
            </div>
            <h4 class="doctor-name text-ellipsis">
              <a href="profile.html">Pamela Curtis</a>
            </h4>

            <div class="user-country">
              <i class="fa fa-map-marker"></i> United States, San Francisco
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-4 col-lg-3">
          <div class="profile-widget">
            <div class="doctor-img">
              <a class="avatar" href="profile.html"
                ><img alt="" src="assets/img/patient4.jpg"
              /></a>
            </div>
            <div class="dropdown profile-action">
              <a
                href="#"
                class="action-icon dropdown-toggle"
                data-toggle="dropdown"
                aria-expanded="false"
                ><i class="fa fa-ellipsis-v"></i
              ></a>
              <div class="dropdown-menu dropdown-menu-right">
                <a class="dropdown-item" href="edit-doctor.html"
                  ><i class="fa fa-pencil m-r-5"></i> Edit</a
                >
                <a
                  class="dropdown-item"
                  href="#"
                  data-toggle="modal"
                  data-target="#delete_doctor"
                  ><i class="fa fa-trash-o m-r-5"></i> Delete</a
                >
              </div>
            </div>
            <h4 class="doctor-name text-ellipsis">
              <a href="profile.html">Cristina Groves</a>
            </h4>

            <div class="user-country">
              <i class="fa fa-map-marker"></i> United States, San Francisco
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-4 col-lg-3">
          <div class="profile-widget">
            <div class="doctor-img">
              <a class="avatar" href="profile.html"
                ><img alt="" src="assets/img/patient5.jpg"
              /></a>
            </div>
            <div class="dropdown profile-action">
              <a
                href="#"
                class="action-icon dropdown-toggle"
                data-toggle="dropdown"
                aria-expanded="false"
                ><i class="fa fa-ellipsis-v"></i
              ></a>
              <div class="dropdown-menu dropdown-menu-right">
                <a class="dropdown-item" href="edit-doctor.html"
                  ><i class="fa fa-pencil m-r-5"></i> Edit</a
                >
                <a
                  class="dropdown-item"
                  href="#"
                  data-toggle="modal"
                  data-target="#delete_doctor"
                  ><i class="fa fa-trash-o m-r-5"></i> Delete</a
                >
              </div>
            </div>
            <h4 class="doctor-name text-ellipsis">
              <a href="profile.html">Marie Wells</a>
            </h4>

            <div class="user-country">
              <i class="fa fa-map-marker"></i> United States, San Francisco
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-4 col-lg-3">
          <div class="profile-widget">
            <div class="doctor-img">
              <a class="avatar" href="profile.html"
                ><img alt="" src="assets/img/patient3.jpg"
              /></a>
            </div>
            <div class="dropdown profile-action">
              <a
                href="#"
                class="action-icon dropdown-toggle"
                data-toggle="dropdown"
                aria-expanded="false"
                ><i class="fa fa-ellipsis-v"></i
              ></a>
              <div class="dropdown-menu dropdown-menu-right">
                <a class="dropdown-item" href="edit-doctor.html"
                  ><i class="fa fa-pencil m-r-5"></i> Edit</a
                >
                <a
                  class="dropdown-item"
                  href="#"
                  data-toggle="modal"
                  data-target="#delete_doctor"
                  ><i class="fa fa-trash-o m-r-5"></i> Delete</a
                >
              </div>
            </div>
            <h4 class="doctor-name text-ellipsis">
              <a href="profile.html">Henry Daniels</a>
            </h4>

            <div class="user-country">
              <i class="fa fa-map-marker"></i> United States, San Francisco
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-4 col-lg-3">
          <div class="profile-widget">
            <div class="doctor-img">
              <a class="avatar" href="profile.html"
                ><img alt="" src="assets/img/patient6.jpg"
              /></a>
            </div>
            <div class="dropdown profile-action">
              <a
                href="#"
                class="action-icon dropdown-toggle"
                data-toggle="dropdown"
                aria-expanded="false"
                ><i class="fa fa-ellipsis-v"></i
              ></a>
              <div class="dropdown-menu dropdown-menu-right">
                <a class="dropdown-item" href="edit-doctor.html"
                  ><i class="fa fa-pencil m-r-5"></i> Edit</a
                >
                <a
                  class="dropdown-item"
                  href="#"
                  data-toggle="modal"
                  data-target="#delete_doctor"
                  ><i class="fa fa-trash-o m-r-5"></i> Delete</a
                >
              </div>
            </div>
            <h4 class="doctor-name text-ellipsis">
              <a href="profile.html">Mark Hunter</a>
            </h4>

            <div class="user-country">
              <i class="fa fa-map-marker"></i> United States, San Francisco
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-4 col-lg-3">
          <div class="profile-widget">
            <div class="doctor-img">
              <a class="avatar" href="profile.html"
                ><img alt="" src="assets/img/patient-thumb-01.jpg"
              /></a>
            </div>
            <div class="dropdown profile-action">
              <a
                href="#"
                class="action-icon dropdown-toggle"
                data-toggle="dropdown"
                aria-expanded="false"
                ><i class="fa fa-ellipsis-v"></i
              ></a>
              <div class="dropdown-menu dropdown-menu-right">
                <a class="dropdown-item" href="edit-doctor.html"
                  ><i class="fa fa-pencil m-r-5"></i> Edit</a
                >
                <a
                  class="dropdown-item"
                  href="#"
                  data-toggle="modal"
                  data-target="#delete_doctor"
                  ><i class="fa fa-trash-o m-r-5"></i> Delete</a
                >
              </div>
            </div>
            <h4 class="doctor-name text-ellipsis">
              <a href="profile.html">Justin Parker</a>
            </h4>

            <div class="user-country">
              <i class="fa fa-map-marker"></i> United States, San Francisco
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="see-all">
            <a class="see-all-btn" href="javascript:void(0);">Load More</a>
          </div>
        </div>
      </div>
    </div>
    <div class="notification-box">
      <div class="msg-sidebar notifications msg-noti">
        <div class="topnav-dropdown-header">
          <span>Messages</span>
        </div>
        <div class="drop-scroll msg-list-scroll" id="msg_list">
          <ul class="list-box">
            <li>
              <a href="chat">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">R</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author">Richard Miles </span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content"
                      >Lorem ipsum dolor sit amet, consectetur adipiscing</span
                    >
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat">
                <div class="list-item new-message">
                  <div class="list-left">
                    <span class="avatar">J</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author">John Doe</span>
                    <span class="message-time">1 Aug</span>
                    <div class="clearfix"></div>
                    <span class="message-content"
                      >Lorem ipsum dolor sit amet, consectetur adipiscing</span
                    >
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">T</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author"> Tarah Shropshire </span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content"
                      >Lorem ipsum dolor sit amet, consectetur adipiscing</span
                    >
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">M</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author">Mike Litorus</span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content"
                      >Lorem ipsum dolor sit amet, consectetur adipiscing</span
                    >
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">C</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author"> Catherine Manseau </span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content"
                      >Lorem ipsum dolor sit amet, consectetur adipiscing</span
                    >
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">D</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author"> Domenic Houston </span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content"
                      >Lorem ipsum dolor sit amet, consectetur adipiscing</span
                    >
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">B</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author"> Buster Wigton </span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content"
                      >Lorem ipsum dolor sit amet, consectetur adipiscing</span
                    >
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">R</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author"> Rolland Webber </span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content"
                      >Lorem ipsum dolor sit amet, consectetur adipiscing</span
                    >
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">C</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author"> Claire Mapes </span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content"
                      >Lorem ipsum dolor sit amet, consectetur adipiscing</span
                    >
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">M</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author">Melita Faucher</span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content"
                      >Lorem ipsum dolor sit amet, consectetur adipiscing</span
                    >
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">J</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author">Jeffery Lalor</span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content"
                      >Lorem ipsum dolor sit amet, consectetur adipiscing</span
                    >
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">L</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author">Loren Gatlin</span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content"
                      >Lorem ipsum dolor sit amet, consectetur adipiscing</span
                    >
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">T</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author">Tarah Shropshire</span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content"
                      >Lorem ipsum dolor sit amet, consectetur adipiscing</span
                    >
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>
        <div class="topnav-dropdown-footer">
          <a href="chat">See all messages</a>
        </div>
      </div>
    </div>
  </div>
  <div id="delete_patient" class="modal fade delete-modal" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
              <div class="modal-body text-center">
                  <img src="assets/img/sent.png" alt="" width="50" height="46">
                  <h3>Are you sure want to delete this Patient?</h3>
                  <div class="m-t-20"> <a href="#" class="btn btn-white" data-dismiss="modal">Close</a>
                      <button type="submit" class="btn btn-danger">Delete</button>
                  </div>
              </div>
          </div>
      </div>

  </div>
</div>

