import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-clinic',
  templateUrl: './add-clinic.component.html',
  styleUrls: ['./add-clinic.component.css']
})
export class AddClinicComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
