import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddAppointmentComponent } from './appointments/components/add-appointment/add-appointment.component';
import { AdminDashboardComponent } from './appointments/components/admin-dashboard/admin-dashboard.component';
import { AllAppointmentsComponent } from './appointments/components/all-appointments/all-appointments.component';
import { ChatComponent } from './chat/components/chat/chat.component';
import { IncommingCallComponent } from './chat/components/incomming-call/incomming-call.component';
import { VideoCallComponent } from './chat/components/video-call/video-call.component';
import { VoiceCallComponent } from './chat/components/voice-call/voice-call.component';
import { ChangePasswordComponent } from './clinics/components/change-password/change-password.component';
import { ClinicInformationComponent } from './clinics/components/clinic-information/clinic-information.component';
import { EmailSettingsComponent } from './clinics/components/email-settings/email-settings.component';
import { NotificationsComponent } from './clinics/components/notifications/notifications.component';
import { AddBlogComponent } from './extras/components/add-blog/add-blog.component';
import { AddClinicComponent } from './clinics/components/add-clinic/add-clinic.component';
import { AddDepartmentsComponent } from './extras/components/add-departments/add-departments.component';
import { AddScheduleComponent } from './appointments/components/add-schedule/add-schedule.component';
import { BlogComponent } from './extras/components/blog/blog.component';
import { ComposeMailComponent } from './extras/components/compose-mail/compose-mail.component';
import { DepartmentsComponent } from './extras/components/departments/departments.component';
import { EditBlogComponent } from './extras/components/edit-blog/edit-blog.component';
import { EmailComponent } from './extras/components/email/email.component';
import { MailviewComponent } from './extras/components/mailview/mailview.component';
import { ProfileComponent } from './extras/components/profile/profile.component';
import { ScheduleComponent } from './appointments/components/schedule/schedule.component';
import { SettingsComponent } from './clinics/components/settings/settings.component';
import { ViewBlogComponent } from './extras/components/view-blog/view-blog.component';
import { AddPetsComponent } from './pets/components/add-pets/add-pets.component';
import { PetsComponent } from './pets/components/pets/pets.component';

import { LoginComponent } from './shared/login/login.component';
import { SignupComponent } from './shared/signup/signup.component';
import { AddDoctorComponent } from './vets/components/add-doctor/add-doctor.component';
import { DoctorsComponent } from './vets/components/doctors/doctors.component';

const routes: Routes = [
  {path: '', redirectTo:'/dashboard',pathMatch:'full'},
  {path: 'login', component:LoginComponent},
  {path: 'signup', component:SignupComponent},


  {path: 'allAppointments', component:AllAppointmentsComponent},
  {path: 'dashboard', component:AdminDashboardComponent},
  {path: 'addAppointments', component:AddAppointmentComponent},

  {path: 'vets', component:DoctorsComponent},
  {path: 'addVets', component:AddDoctorComponent},

  {path: 'pets', component:PetsComponent},
  {path: 'addPets', component:AddPetsComponent},

  {path: 'schedule', component:ScheduleComponent},
  {path: 'addSchedule', component:AddScheduleComponent},

  {path: 'departments', component:DepartmentsComponent},
  {path: 'addDepartment', component:AddDepartmentsComponent},

  {path: 'chat', component:ChatComponent},
  {path: 'incommingCall', component:IncommingCallComponent},
  {path: 'videoCall', component:VideoCallComponent},
  {path: 'voiceCall', component:VoiceCallComponent},

  {path: 'composeMail', component:ComposeMailComponent},
  {path: 'inbox', component:EmailComponent},
  {path: 'mailview', component:MailviewComponent},

  {path: 'blog', component:BlogComponent},
  {path: 'addBlog', component:AddBlogComponent},
  {path: 'editBlog', component:EditBlogComponent},
  {path: 'viewBlog', component:ViewBlogComponent},

  {path: 'clinic', component:ClinicInformationComponent},
  {path: 'addClinic', component:AddClinicComponent},

  {path: 'profile-setting', component:ProfileComponent},

  {path: 'settings', component:SettingsComponent},
  {path: 'emailSetting', component:EmailSettingsComponent},
  {path: 'notifications', component:NotificationsComponent},
  {path: 'changePassword', component:ChangePasswordComponent},


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
