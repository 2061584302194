import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-pets',
  templateUrl: './add-pets.component.html',
  styleUrls: ['./add-pets.component.css']
})
export class AddPetsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
