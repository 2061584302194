<div class="main-wrapper">
  <app-header></app-header>
  <div class="sidebar" id="sidebar">
      <div class="sidebar-inner slimscroll">
          <div class="sidebar-menu">
              <ul>
                  <li>
                      <a href="dashboard"><i class="fa fa-home back-icon"></i> <span>Back to Home</span></a>
                  </li>
                  <li class="menu-title">Settings</li>
                  <li class="active">
                      <a href="settings.html"><i class="fa fa-building"></i> <span>Company Settings</span></a>
                  </li>
                  <li>
                      <a href="emailSetting"><i class="fa fa-envelope-o"></i> <span>Email Settings</span></a>
                  </li>
                  <li>
                      <a href="notifications"><i class="fa fa-globe"></i> <span>Notifications</span></a>
                  </li>
                  <li>
                      <a href="changePassword"><i class="fa fa-lock"></i> <span>Change Password</span></a>
                  </li>

              </ul>
          </div>
      </div>
  </div>
  <div class="page-wrapper">
      <div class="content">
          <div class="row">
              <div class="col-lg-8 offset-lg-2">
                  <form>
                      <div class="form-group">
                          <div class="form-check form-check-inline">
                              <input class="form-check-input" type="radio" name="mailoption" id="phpmail" value="option1">
                              <label class="form-check-label" for="phpmail">PHP Mail</label>
                          </div>
                          <div class="form-check form-check-inline">
                              <input class="form-check-input" type="radio" name="mailoption" id="smtpmail" value="option2">
                              <label class="form-check-label" for="smtpmail">SMTP</label>
                          </div>
                      </div>
                      <h4 class="page-title">PHP Email Settings</h4>
                      <div class="row">
                          <div class="col-sm-6">
                              <div class="form-group">
                                  <label>Email From Address</label>
                                  <input class="form-control" type="email">
                              </div>
                          </div>
                          <div class="col-sm-6">
                              <div class="form-group">
                                  <label>Emails From Name</label>
                                  <input class="form-control" type="text">
                              </div>
                          </div>
                      </div>
                      <h4 class="page-title m-t-30">SMTP Email Settings</h4>
                      <div class="row">
                          <div class="col-sm-6">
                              <div class="form-group">
                                  <label>SMTP HOST</label>
                                  <input class="form-control" type="text">
                              </div>
                          </div>
                          <div class="col-sm-6">
                              <div class="form-group">
                                  <label>SMTP USER</label>
                                  <input class="form-control" type="text">
                              </div>
                          </div>
                          <div class="col-sm-6">
                              <div class="form-group">
                                  <label>SMTP PASSWORD</label>
                                  <input class="form-control" type="password">
                              </div>
                          </div>
                          <div class="col-sm-6">
                              <div class="form-group">
                                  <label>SMTP PORT</label>
                                  <input class="form-control" type="text">
                              </div>
                          </div>
                          <div class="col-sm-6">
                              <div class="form-group">
                                  <label>SMTP Security</label>
                                  <select class="select">
                                      <option>None</option>
                                      <option>SSL</option>
                                      <option>TLS</option>
                                  </select>
                              </div>
                          </div>
                          <div class="col-sm-6">
                              <div class="form-group">
                                  <label>SMTP Authentication Domain</label>
                                  <input class="form-control" type="text">
                              </div>
                          </div>
                      </div>
                      <div class="col-sm-12 m-t-20 text-center">
                          <button type="button" class="btn btn-primary submit-btn">Save</button>
                      </div>
                  </form>
              </div>
          </div>
      </div>
      <div class="notification-box">
          <div class="msg-sidebar notifications msg-noti">
              <div class="topnav-dropdown-header">
                  <span>Messages</span>
              </div>
              <div class="drop-scroll msg-list-scroll" id="msg_list">
                  <ul class="list-box">
                      <li>
                          <a href="chat">
                              <div class="list-item">
                                  <div class="list-left">
                                      <span class="avatar">R</span>
                                  </div>
                                  <div class="list-body">
                                      <span class="message-author">Richard Miles </span>
                                      <span class="message-time">12:28 AM</span>
                                      <div class="clearfix"></div>
                                      <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                  </div>
                              </div>
                          </a>
                      </li>
                      <li>
                          <a href="chat">
                              <div class="list-item new-message">
                                  <div class="list-left">
                                      <span class="avatar">J</span>
                                  </div>
                                  <div class="list-body">
                                      <span class="message-author">John Doe</span>
                                      <span class="message-time">1 Aug</span>
                                      <div class="clearfix"></div>
                                      <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                  </div>
                              </div>
                          </a>
                      </li>
                      <li>
                          <a href="chat">
                              <div class="list-item">
                                  <div class="list-left">
                                      <span class="avatar">T</span>
                                  </div>
                                  <div class="list-body">
                                      <span class="message-author"> Tarah Shropshire </span>
                                      <span class="message-time">12:28 AM</span>
                                      <div class="clearfix"></div>
                                      <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                  </div>
                              </div>
                          </a>
                      </li>
                      <li>
                          <a href="chat">
                              <div class="list-item">
                                  <div class="list-left">
                                      <span class="avatar">M</span>
                                  </div>
                                  <div class="list-body">
                                      <span class="message-author">Mike Litorus</span>
                                      <span class="message-time">12:28 AM</span>
                                      <div class="clearfix"></div>
                                      <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                  </div>
                              </div>
                          </a>
                      </li>
                      <li>
                          <a href="chat">
                              <div class="list-item">
                                  <div class="list-left">
                                      <span class="avatar">C</span>
                                  </div>
                                  <div class="list-body">
                                      <span class="message-author"> Catherine Manseau </span>
                                      <span class="message-time">12:28 AM</span>
                                      <div class="clearfix"></div>
                                      <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                  </div>
                              </div>
                          </a>
                      </li>
                      <li>
                          <a href="chat">
                              <div class="list-item">
                                  <div class="list-left">
                                      <span class="avatar">D</span>
                                  </div>
                                  <div class="list-body">
                                      <span class="message-author"> Domenic Houston </span>
                                      <span class="message-time">12:28 AM</span>
                                      <div class="clearfix"></div>
                                      <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                  </div>
                              </div>
                          </a>
                      </li>
                      <li>
                          <a href="chat">
                              <div class="list-item">
                                  <div class="list-left">
                                      <span class="avatar">B</span>
                                  </div>
                                  <div class="list-body">
                                      <span class="message-author"> Buster Wigton </span>
                                      <span class="message-time">12:28 AM</span>
                                      <div class="clearfix"></div>
                                      <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                  </div>
                              </div>
                          </a>
                      </li>
                      <li>
                          <a href="chat">
                              <div class="list-item">
                                  <div class="list-left">
                                      <span class="avatar">R</span>
                                  </div>
                                  <div class="list-body">
                                      <span class="message-author"> Rolland Webber </span>
                                      <span class="message-time">12:28 AM</span>
                                      <div class="clearfix"></div>
                                      <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                  </div>
                              </div>
                          </a>
                      </li>
                      <li>
                          <a href="chat">
                              <div class="list-item">
                                  <div class="list-left">
                                      <span class="avatar">C</span>
                                  </div>
                                  <div class="list-body">
                                      <span class="message-author"> Claire Mapes </span>
                                      <span class="message-time">12:28 AM</span>
                                      <div class="clearfix"></div>
                                      <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                  </div>
                              </div>
                          </a>
                      </li>
                      <li>
                          <a href="chat">
                              <div class="list-item">
                                  <div class="list-left">
                                      <span class="avatar">M</span>
                                  </div>
                                  <div class="list-body">
                                      <span class="message-author">Melita Faucher</span>
                                      <span class="message-time">12:28 AM</span>
                                      <div class="clearfix"></div>
                                      <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                  </div>
                              </div>
                          </a>
                      </li>
                      <li>
                          <a href="chat">
                              <div class="list-item">
                                  <div class="list-left">
                                      <span class="avatar">J</span>
                                  </div>
                                  <div class="list-body">
                                      <span class="message-author">Jeffery Lalor</span>
                                      <span class="message-time">12:28 AM</span>
                                      <div class="clearfix"></div>
                                      <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                  </div>
                              </div>
                          </a>
                      </li>
                      <li>
                          <a href="chat">
                              <div class="list-item">
                                  <div class="list-left">
                                      <span class="avatar">L</span>
                                  </div>
                                  <div class="list-body">
                                      <span class="message-author">Loren Gatlin</span>
                                      <span class="message-time">12:28 AM</span>
                                      <div class="clearfix"></div>
                                      <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                  </div>
                              </div>
                          </a>
                      </li>
                      <li>
                          <a href="chat">
                              <div class="list-item">
                                  <div class="list-left">
                                      <span class="avatar">T</span>
                                  </div>
                                  <div class="list-body">
                                      <span class="message-author">Tarah Shropshire</span>
                                      <span class="message-time">12:28 AM</span>
                                      <div class="clearfix"></div>
                                      <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                  </div>
                              </div>
                          </a>
                      </li>
                  </ul>
              </div>
              <div class="topnav-dropdown-footer">
                  <a href="chat">See all messages</a>
              </div>
          </div>
      </div>
  </div>
</div>
